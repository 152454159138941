<template>
  <div class="landingContainer">
        <h1 class="pt-5 text-center text-black font-weight-bold">Feria de Belleza y Moda 2024</h1>

        <div class="d-md-flex justify-content-center">

            <div class="col d-flex justify-content-center align-items-center" ref="requestFormContainer">
                <card class="col col-md-12 containerForm mx-3">                    
                    <form @submit.prevent="sendForm">

                        <base-input
                            name="rep_full_name"
                            v-model="form.rep_full_name"
                            label="Nombre y apellidos"
                        />

                        <base-input
                            name="company_name"
                            v-model="form.company_name"
                            label="Negocio"
                        />

                        <div  class="mb-3">
                          <div>
                            <label class="control-label mt-4 mt-md-0 pb-md-1">
                                ¿Eres tu el dueño o gestor del negocio?
                            </label>
                          </div>
                          <base-radio
                            inline
                            name="si"
                            class="mt-0"
                            v-model="form.is_owner_or_manager"
                            >Si</base-radio
                          >
                          <base-radio
                            inline
                            name="no"
                            class="mt-0"
                            v-model="form.is_owner_or_manager"
                            >No</base-radio
                          >
                        </div>

                        <base-input
                            inputType="email"
                            name="email"
                            v-model="form.email"
                            label="Email"
                            :classes="form.email !== null && checkEmailIsInvalid() ? 'border border-danger' : ''"
                        />

                        <base-input
                            inputType="tel"
                            name="phone"
                            v-model="form.phone"
                            label="Teléfono"
                        />

                        <base-input
                            name="n_employees"
                            v-model="form.n_employees"
                            label="Numero de agendas/empleados"
                        />

                        <div  class="mb-3">
                          <div>
                            <label class="control-label mt-4 mt-md-0 pb-md-1">
                                ¿Tiene canal de citas online?
                            </label>
                          </div>
                          <base-radio
                            inline
                            name="si"
                            class="mt-0"
                            v-model="form.have_channel"
                            >Si</base-radio
                          >
                          <base-radio
                            inline
                            name="no"
                            class="mt-0"
                            v-model="form.have_channel"
                            >No</base-radio
                          >
                        </div>

                        <base-input
                            v-if="form.have_channel === 'si'"
                            name="which_one"
                            v-model="form.which_one"
                            label="¿Cual?"
                        />

                        <base-input
                            v-if="form.have_channel === 'si'"
                            name="is_sponsored"
                            v-model="form.is_sponsored"
                            label="¿Y estás solo tu negocio o apareces en un listado con patrocinados?"
                        />

                        <base-input
                            v-if="form.have_channel === 'si'"
                            name="steal_clients"
                            v-model="form.steal_clients"
                            label="¿Alguna vez te han robado clientes?"
                        />


                        <p>Y si te digo que puedes tener tu propia aplicación de citas subida en las stores a disposicion de tus clientes por <b>250€?</b> Podrías personalizarla con la imagen de tu negocio y el coste posterior serían desde <b>25€</b> al mes tu agenda gestionada, adicionalmente 5€ por agenda extra. Dispondrías además de otras funcionalidades como inventario, control de gastos/ingresos, etc.</p>

                        <div  class="mb-3">
                          <div>
                            <label class="control-label mt-4 mt-md-0 pb-md-1">
                                ¿Te parece interesante?
                            </label>
                          </div>
                          <base-radio
                            inline
                            name="si"
                            class="mt-0"
                            v-model="form.is_interest"
                            >Si</base-radio
                          >
                          <base-radio
                            inline
                            name="no"
                            class="mt-0"
                            v-model="form.is_interest"
                            >No</base-radio
                          >
                        </div>

                        <VueSignaturePad
                            id="signature"
                            width="100%"
                            height="15em"
                            ref="signature"
                            :options="signOptions"
                            :class="sending && !form.signature? 'border border-danger' : ''"
                        />
                        
                        <base-button
                            class="my-2 btn-simple d-flex justify-content-center"
                            size="lg"
                            @click="signClear"
                            block>
                            <span>{{ $t('serviceContractSection.clearSignature') }}</span>
                        </base-button>

                        <div class="d-flex align-items-center mt-2">
                            <base-checkbox
                                v-model="form.dataTreatment"
                                class="mb-2"
                            >
                                <span>{{ $t("acceptConditionsOfThe") }}</span>
                            </base-checkbox>
                            <span class="checkboxLink" @click="openPdf('dataTreatment')">
                                {{ $t('dataTreatment') }}
                            </span>
                        </div>

                        <base-button
                            native-type="submit"                            
                            type="success"
                            class="mb-2 startTestBtn d-flex justify-content-center"
                            size="lg"
                            block>
                            <span>Enviar información</span>
                            <Spinner v-if="sending" classes="text-info ml-4"></Spinner>
                        </base-button>
                    </form>
                </card>
            </div>
        </div>
  </div>
</template>

<script>
import { BaseInput, Card, Spinner,  } from "@/components/index";
import { mapState } from 'vuex';
import { createFormValidation } from "@lemoncode/fonk";

export default {
    data() {
        return {
            form: {
                rep_full_name: null,
                company_name: null,
                is_owner_or_manager:null,
                email: null,
                phone: null,
                n_employees: null,
                have_channel: null,
                which_one: null,
                is_sponsored: null,
                steal_clients: null,
                signature: null,
                is_interest:null,
                dataTreatment: false,
            },
            sending: false,
            signIsEmpty: true,           
            isViewDesktop: false,
            signOptions: {
                minWidth: 2,
                maxWidth: 6,
                onEnd: () => this.signChange()
            },            
        }
    },
    methods: {
        checkEmailIsInvalid() {
            const mailFormat = /^[\w-.]+@([\w-]+\.)+([\w-]+)$/g;
            return !mailFormat.test(this.form.email);
        },
        async sendForm() {
            this.sending = true;
            const { data } = this.$refs.signature.saveSignature();
            this.form.signature = data;
            const route = '/beauty-form';

            let form = {...this.form};

            console.log(form);
            try {
                const response = await this.axios.post(route, form)
                if(response && response.data.status === "success"){
                    this.$toast.success(this.$t("notifications.success.contact"));
                    this.signClear();
                    this.formClear();
                }
            } catch (error) {
                this.$toast.error(this.$t("notifications.error.tryLater"));
                console.log(error);
            } finally {
                this.sending = false;
            }
        },
        checkViewDesktop () {
            this.isViewDesktop = window.innerWidth >= 768;
        },
        signClear() {
          this.$refs.signature.clearSignature();
          this.signChange()
        },
        formClear() {
            this.form = {
                rep_full_name: null,
                company_name: null,
                is_owner_or_manager:null,
                email: null,
                phone: null,
                n_employees: null,
                have_channel: null,
                which_one: null,
                is_sponsored: null,
                steal_clients: null,
                signature: null,
                is_interest:null,
                dataTreatment: false,
            };
        },
        signChange(){
          const {isEmpty} = this.$refs.signature.saveSignature();
          this.signIsEmpty = isEmpty;
        },
        async openPdf(pdfName){
            const urlFile = {
                dataTreatment: './pdf/LPD-AS-SALOON.pdf'                
            }

            window.open(urlFile[pdfName], '_blank').focus();
        }
    },
    components: {
        BaseInput,
        Card
    },
    created() {
        window.addEventListener("resize", this.checkViewDesktop);
    },
    mounted() {
        const queryRef = this.$route.query.code;
        const scrollTop = 0;
        if(window.scrollY !== scrollTop) window.scrollTo(0,0);
        
        this.checkViewDesktop();

        if(queryRef) {
            this.form.code = queryRef;
            this.isReferer = true;
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.checkViewDesktop);
    },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/white-dashboard.scss';

#signature {
    border: solid 1px rgba(34, 42, 66, 0.5);
    border-radius: 0.4285rem;
    cursor: crosshair;
}

.containerForm {
    border-radius: 1em;
}
.phrase {
    font-size: 1.6em;
    font-weight: bold;
    line-height: 3.5rem;
}
.phrase-cta {
    font-size: 2.2em;
    font-weight: bold;
}

.startFreeTrialBtn {
    height: 5em;
    font-size: 1.5em;
}

.startFreeTrialBtn > span,
.startTestBtn > span {
    white-space: break-spaces;
}

.card-cto {
    border: 2px solid $primary;
}
.landing-img{
    width: 100%;
    max-width: 500px;
    margin: 0 auto 1rem;
}
.landingContainer {
    max-width: 1500px;
    margin: 0 auto;

    .header-icons-texts {
        font-weight: normal;
        font-size: 15px;
    }

    .bg-white {
        border-radius: 1rem;
    }

    .text-cta {
        h2{
            color: #088375;
            margin: 10px 0;
            font-size: 2rem;
        }

        h3 {
            margin-bottom: 10px!important;
        }
    }

    .description-text {
        font-weight: 400!important;

        span{
            font-weight: bolder!important;
        }
    }
}
.checkboxLink {
    font-weight: bold;
    font-size: 0.75rem;
    margin-left: 2px;
    margin-top: 1px;
    cursor: pointer;
}
@media (min-width: 768px) {
    .title-text {
        font-size: 1.3rem;
        font-weight: 600;
        text-align: left;
    }
    .description-text {
        font-size: 1rem;
    }
}
</style>